import { ActionContext } from 'vuex';

import api from '@/services/api';
import http from '@/services/api/http';
import { ArticleMail } from '@/services/api/modules/article.types';
import { Source } from '@/services/api/modules/sources';
import { State as R } from '@/store/index';

export interface State {
  items: Source[];
}

export default {
  namespaced: true,

  state: {
    items: [],
  },

  mutations: {
    setItems(state: State, items: Source[]): void {
      state.items = items;
    },
  },

  actions: {
    async fetchItems({ commit }: ActionContext<State, R>): Promise<void> {
      const items = await api.sources.index();
      commit('setItems', items);
    },

    async sendMail(
      { commit }: ActionContext<State, R>,
      item: ArticleMail,
    ): Promise<void> {
      return http.post(`/sources/${item.resourceId}/items/${item.id}/mail`, {
        username: item.email,
        subject: item.subject,
        comment: item.message,
      });
    },

    async follow(
      { commit }: ActionContext<State, R>,
      item: ArticleMail,
    ): Promise<void> {
      return http.get(`/sources/${item.resourceId}/items/${item.id}/follow`);
    },

    async unfollow(
      { commit }: ActionContext<State, R>,
      item: ArticleMail,
    ): Promise<void> {
      return http.get(`/sources/${item.resourceId}/items/${item.id}/unfollow`);
    },
  },

  getters: {},
};
