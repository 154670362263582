
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
} from '@vue/runtime-core';
import { format } from 'date-fns/esm';
import nl from 'date-fns/locale/nl';
import { groupBy, mapValues, pickBy } from 'lodash';
import { useStore } from 'vuex';

import config from '@/config';
import { Newsletter } from '@/services/api/modules/newsletters';
import { State } from '@/store';

import DeleteNewsletters from '../modals/DeleteNewsletters.vue';
import MailNewsletter from '../modals/MailNewsletter.vue';

export default defineComponent({
  components: {
    DeleteNewsletters,
    MailNewsletter,
  },

  setup() {
    const store = useStore<State>();
    const newsletter = ref();
    const mailModal = ref();
    const deleteModal = ref();
    const showError = ref();

    const baseUri = computed(() => config.api.uri);
    const items = computed(() => store.state.newsletters.items);
    const selected = ref(mapValues(groupBy(items.value, 'id'), () => false));
    const selectedIds = computed(() =>
      Object.keys(pickBy(selected.value, Boolean)),
    );

    const formatDate = (item: Newsletter) =>
      format(new Date(item.created), 'd LLL yyyy — HH:mm', { locale: nl });

    const showDelete = () => {
      if (selectedIds.value?.length) {
        deleteModal.value?.show();
      }
    };

    const showMail = (item: Newsletter) => {
      newsletter.value = item;
      mailModal.value?.show();
    };

    watch(items, () => {
      selected.value = mapValues(groupBy(items.value, 'id'), () => false);
    });

    onMounted(() => {
      store.dispatch('newsletters/fetchItems');
    });

    watch([items, selectedIds], () => {
      if (showError.value) showError.value = false;
    });

    return {
      newsletter,
      showError,
      mailModal,
      showMail,
      deleteModal,
      showDelete,
      baseUri,
      items,
      selected,
      selectedIds,
      formatDate,
    };
  },
});
