
import { format } from 'date-fns';
import isSameDay from 'date-fns/isSameDay/index';
import { nl } from 'date-fns/locale';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

import config from '@/config';
import { Article } from '@/services/api/modules/article.types';
import { State } from '@/store';

export default defineComponent({
  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
    docked: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const store = useStore<State>();

    const baseUri = computed(() => config.api.uri);

    const sameDay = computed(
      () =>
        props.article.eventStart &&
        props.article.eventEnd &&
        isSameDay(
          new Date(props.article.eventStart),
          new Date(props.article.eventEnd),
        ),
    );

    const start = computed(() => {
      if (!props.article.eventStart) return undefined;

      const date = new Date(props.article.eventStart);

      return {
        weekday: format(date, 'EEEE', { locale: nl }),
        day: format(date, 'd', { locale: nl }),
        month: format(date, 'MMM', { locale: nl }),
        year: format(date, 'yyyy', { locale: nl }),
        time: format(date, 'HH:mm', { locale: nl }),
      };
    });

    const end = computed(() => {
      if (!props.article.eventEnd) return undefined;

      const date = new Date(props.article.eventEnd);

      return {
        weekday: format(date, 'EEEE', { locale: nl }),
        day: format(date, 'd', { locale: nl }),
        month: format(date, 'MMM', { locale: nl }),
        year: format(date, 'yyyy', { locale: nl }),
        time: format(date, 'HH:mm', { locale: nl }),
      };
    });

    return {
      baseUri,
      sameDay,
      start,
      end,
    };
  },
});
