
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import { computed, defineComponent } from 'vue';

import config from '@/config';
import { ARTICLE_TYPES } from '@/config/enums';
import { Article } from '@/services/api/modules/article.types';
import { Resource } from '@/services/api/modules/workspaces';
import { State } from '@/store';

import Calendar from './Calendar.vue';
import SourceMedia from './SourceMedia.vue';

export default defineComponent({
  components: {
    Calendar,
    SourceMedia,
  },

  props: {
    article: {
      type: Object as () => Article,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    resource: {
      type: Object as () => Resource,
      required: false,
    },
    docked: {
      type: Boolean,
      required: true,
    },
  },

  setup(props) {
    const baseUri = computed(() => config.api.uri);

    const pubDate = computed(
      () => props.article.pubDate && new Date(props.article.pubDate),
    );

    const resourceId = computed(() => {
      return props.resource ? props.resource.resourceId : undefined;
    });

    const formattedDate = computed(() => {
      return pubDate.value
        ? format(pubDate.value, 'EEEE dd MMMM yyyy', { locale: nl })
        : undefined;
    });

    const formattedTime = computed(
      () => pubDate.value && format(pubDate.value, 'HH:mm'),
    );

    const termsLinks = computed(() =>
      props.article.links
        ? props.article.links.filter((link) => link.kind === 'terms')
        : [],
    );

    return {
      ARTICLE_TYPES,
      baseUri,
      formattedDate,
      formattedTime,
      resourceId,
      termsLinks,
    };
  },
});
